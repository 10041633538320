import PropTypes from "prop-types";
import classes from "./styles.module.scss";
import { getRoute } from "@/functions/getRoute";
import { getGlobal } from "@/functions/getGlobal";
import PersonAddOutline from "@/assets/PersonAddOutline.svg";
import SectorsButton from "@/assets/SectorsButton.svg";
import Attach from "@/assets/Attach.svg";
import Search from "@/assets/white-search.svg";
import Home from "@/assets/icons-home.svg";
import Link from "next/link";
import { useEffect, useState } from "react";
import { NavbarToggleButton } from "./__components";
import SiteHeaderNav from "./__components/SiteHeaderNav";
import { useRouter } from "next/router";
import { Clocks } from "@/ui/SiteHeader/__components/Clocks";

export default function SiteHeader({ className = "" }) {
  const router = useRouter()
  const global = getGlobal();
  const [scrollY, setScrollY] = useState(0);
  const [active, setActive] = useState(false);

  //dropdown menu
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setIsOpen(false);
  };
  const links = [
    { value: "Home", url: "/", icon: <Home /> },
    { value: "Job Search", url: "/jobs", icon: <Search /> },
    { value: "Upload your CV", url: "/upload-your-cv", icon: <Attach /> },
    { value: "Submit vacancy", url: "/submit-vacancy", icon: <PersonAddOutline /> },
    // { value: "Sectors", url: "/sectors/manufacturing/", icon: <SectorsButton /> },
  ];

  // const dropDownItems = [
  //   { value: "Manufacturing", url: "/sectors/manufacturing/"},
  //   { value: "Technology", url: "/sectors/technology"},
  //   { value: "Engineering", url: "/sectors/engineering/"},
  // ];;

  useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [router])

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    handleScroll();

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function toggle() {
    setActive(!active);
  }

  function handleRouteChange() {
    setActive(false);
  }

  return (
    <>
      <div className={`${classes.header} ${scrollY > 0 ? classes.scrolled : ""}`}>
        <div className={classes.container}>
          <div className={classes.toggle} href={getRoute("home")}>
            <NavbarToggleButton scrollY={scrollY} active={active} onClick={toggle} />
          </div>
          {/* <Clocks theme={scrollY > 0 ? "dark" : "light"} /> */}
          <div className={classes.links}>
            {
              links.map((item, index) => {
                return <Link key={index} className={classes.link} href={item.url}>
                  <div className={classes.desc}>{item.value}</div>
                  {item.icon}
                </Link>
              })
            }
                 {/* <div className="dropdown-toggle">
      <button  onClick={toggleDropdown} className={classes.button}>
        Sectors
      </button>
      {isOpen && (
          <div className={classes.dropDownMenu}>
         {dropDownItems.map((item, index) => (
           <Link key={index} className={classes.dropdownLink} href={item.url} onClick={() => handleItemClick(item)}>
            <div className={classes.dropDownDesc}>{item.value}</div>
            {item.icon}
          </Link>
          ))}
        </div>
      )}
    </div> */}
          </div>
     
        </div>
        <SiteHeaderNav active={active} className={classes.navlink_cont} />
      </div>
      <div className={classes.header_padding}></div>
    </>
  );
}

SiteHeader.propTypes = {
  className: PropTypes.string,
};
