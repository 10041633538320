import { useCallback, useState } from "react";
import { getHeaderMenu } from "@/functions/getHeaderMenu";
import classes from "./styles.module.scss";
import Link from "next/link";
import Title from "@/ui/Title";
import Body from "@/ui/Body";
import Location from "@/assets/Location.svg";
import Phone from "@/assets/Phone.svg";
import Email from "@/assets/Email.svg";
import { getGlobal } from "@/functions/getGlobal";
import Facebook from "@/assets/Facebook2.svg";
import LinkedIn from "@/assets/LinkedIn.svg";
import Instagram from "@/assets/Instagram.svg";
import Image from "@/ui/Image";
import clsx from "classnames";

export default function SiteHeaderNav({ active, className }) {
  const global = getGlobal();
  const facebook = global["_theme.social.facebook"];
  const linkedin = global["_theme.social.linkedin"];
  const instagram = global["_theme.social.instagram"];
  const nav = getHeaderMenu();

  //dropdown menu
  const [open, setOpen] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);

  const toggleDropdown = useCallback((index) => {
    setOpen(index === open ? null : index);
  }, [open]);

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setOpen(null);
  };

  const dropDownItems = [
     { value: "Manufacturing", url: "/sectors/manufacturing/"},
     { value: "Technology", url: "/sectors/technology"},
     { value: "Engineering", url: "/sectors/engineering/"},
   ];

  const content = {
    subtitle: {
      path: "_theme.nav.subtitle",
      placeholder: "Talent Driven Growth"
    },
    contact_title: {
      path: "_theme.contact.title",
      placeholder: "Contact Us"
    },
    address3: {
      path: "_theme.company.address3",
      placeholder: "Contact Us"
    },
    address2: {
      path: "_theme.company.address2",
      placeholder: "Contact Us"
    },
    address: {
      path: "_theme.company.address",
      placeholder: "Contact Us"
    },
    email: {
      path: "_theme.company.email",
      placeholder: "Contact Us"
    },
    number: {
      path: "_theme.company.number",
      placeholder: "Contact Us"
    },
  }

  if (!active) return <></>;

  return (
    <div className={`${classes.nav_cont} ${className}`}>
      <div className={classes.container}>
        <Image img="/patterns/wormhole-line.webp" size="1440x560" alt="footer pattern" className={classes.pattern} />
        <div className={classes.left_cont}>
          {nav.map((item, index) => (
            <>
              {item.children.length > 1 ? (
                <div className="dropdown-toggle" key={index}>
                  <button onClick={() => toggleDropdown(index)} className={`h4 ${classes.button}`}>
                    Sectors <span className={clsx(classes.icon, {[classes.open]: open === index})}>+</span>
                  </button>
                  <div className={clsx(classes.dropDownMenu, { open: open === index })}>
                    {item.children.map((item, index) => (
                      <Link
                        key={index}
                        className={classes.dropdownLink}
                        href={item.url}
                        onClick={() => handleItemClick(item)}
                      >
                        <div className={`h4 ${classes.dropDownDesc}`}>{item.title}</div>
                      </Link>
                    ))}
                  </div>
                </div>
              ) : (
                <div key={index}>
                  <Link href={item.url} className={`h4 ${classes.link}`}>
                    {item.title}
                  </Link>
                </div>
              )}
            </>
          ))}
        </div>
        <div className={classes.right_cont}>
          <div className={classes.top_cont}>
            <img src="/images/omega-logo.png" alt="" width={221} height={72} className={classes.icon} />
            <Body body={content.subtitle} className={classes.subtitle} />
          </div>
          <div className={classes.bottom_cont}>
            <div className={classes.contact_cont}>
              <Title title={content.contact_title} tag="h4" className={classes.contact_title} />
              <div className={classes.highlights}>
                <div className={classes.highlight}>
                  <Location width={18} height={18} className={`fill white ${classes.icon}`} />
                  <Body body={content.address} className={classes.value} />
                </div>
                <div className={classes.highlight}>
                  <Location width={18} height={18} className={`fill white ${classes.icon}`} />
                  <Body body={content.address2} className={classes.value} />
                </div>
                <div className={classes.highlight}>
                  <Location width={18} height={18} className={`fill white ${classes.icon}`} />
                  <Body body={content.address3} className={classes.value} />
                </div>
                <div className={classes.highlight}>
                  <Phone width={18} height={18} className={`fill white ${classes.icon}`} />
                  <Body body={content.number} className={classes.value} />
                </div>
                <div className={classes.highlight}>
                  <Email width={18} height={18} className={`fill white ${classes.icon}`} />
                  <Body body={content.email} className={classes.value} />
                </div>
              </div>
            </div>
            <div className={classes.socials}>
              {facebook && (
                <a className={classes.social_icon} href={facebook} target="_blank" rel="nofollow">
                  <Facebook width={19} height={19} />
                </a>
              )}
              {linkedin && (
                <a className={classes.social_icon} href={linkedin} target="_blank" rel="nofollow">
                  <LinkedIn width={19} height={19} />
                </a>
              )}
              {instagram && (
                <a className={classes.social_icon} href={instagram} target="_blank" rel="nofollow">
                  <Instagram width={19} height={19} />
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}